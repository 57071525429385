.Homepage {
    max-width: 100%;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    
  }
  
  .mainFix{
    margin: 0;
    padding: 0;
    overflow: hidden
  }
  
  .mission_logo {
    border: 2px solid #A6A6A6;
    width: auto;
    height: auto;
    position: relative;
    z-index: 4;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    box-shadow: 3px 4px #a7a6a6;
  }
  
  .mobilemission_logo {
    border: 2px solid #A6A6A6;
    width: 100%;
    height: auto;
    position: relative;
    z-index: 4;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    box-shadow: 3px 4px #a7a6a6;
  }
  
  .cover_fog {
    background-image: url(../../imgs/background_fog.png);
    background-size: cover;
    width: 100%;
    height: 1080px;
    opacity: 0.5;
    position: relative;
  }
  
  .line_placement {
    top: 1200px;
    bottom: auto;
    left: auto;
    right: auto;
  }
  
  .menu_button {
    background-color: #FF5757;
    border: #47525E;
    border-radius: 100%;
    width: 81px;
    height: 81px;
    position: absolute;
    z-index: 5;
    top: 49px;
    bottom: auto;
    left: 79px;
    right: auto;
  }
  
  .menu_button:hover {background-color: #ee8282}
  
  .menu_button:active {
    background-color: #828282;
    box-shadow: 0 2px #666;
    transform: translateY(1px);
  }
  
  .mobilemenu_button {
    background-color: #FF5757;
    border: #47525E;
    border-radius: 100%;
    width: 81px;
    height: 81px;
    position: absolute;
    z-index: 5;
    top: 49px;
    bottom: auto;
    left: 40px;
    right: auto;
  }
  
  .mobilemenu_button:hover {background-color: #ee8282}
  
  .mobilemenu_button:active {
    background-color: #828282;
    box-shadow: 0 2px #666;
    transform: translateY(1px);
  }
  
  .mission_container {
    background-color: #777474;
    width: auto;
    height: auto;
    min-width: 503px;
    max-height: fit-content;
    position: relative;
    z-index: 6;
  
    top: 10px;
    color: #FFFFFF;
    font-family: Lato;
    font-size: 100%;
    letter-spacing: 5px;
    line-height: auto;
    max-width: 789px;
    text-align: center;
    box-shadow: 4px 4px #a7a6a6;
  
  }
  
  .mobilemission_container {
    background-color: #777474;
    width: 100%;
    height: auto;
    
    max-height: fit-content;
    position: relative;
    z-index: 6;
  
    top: 10px;
    color: #FFFFFF;
    font-family: Lato;
    font-size: 100%;
    letter-spacing: 5px;
    line-height: auto;
    max-width: 789px;
    text-align: center;
    box-shadow: 4px 4px #a7a6a6;
  
  }
  
  .mission_title{
    color: #FFFFFF;
    font-family: Lato;
    font-size: 300%;
    line-height: 65px;
    top: auto;
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;
    z-index: 5;
    font-weight: 700;
  }
  
  .mission_description{
    color: #FFFFFF;
    font-family: Lato;
    font-size: 160%;
    line-height: 130%;
    letter-spacing: normal;
    line-height: 130%;
    top: auto;
    height: fit-content;
    width: 97%;
    text-align: center;
    position: relative;
    z-index: 5;
    padding-left: 1%;
    padding-bottom: 1%;
  }
  
  .up_button {
    background-color: #FF5757;
    border: #47525E;
    border-radius: 100%;
    width: 81px;
    height: 81px;
    position: relative;
    z-index: 5;
    top: auto;
    bottom: auto;
    left: -45%;
    right: auto;
  }
  
  .up_button:hover {background-color: #ee8282}
  
  .up_button:active {
    background-color: #828282;
    box-shadow: 0 2px #666;
    transform: translateY(1px);
  }
  
  .tabletup_button {
    background-color: #FF5757;
    border: #47525E;
    border-radius: 100%;
    width: 81px;
    height: 81px;
    position: relative;
    z-index: 5;
    top: 13px;
    bottom: auto;
    left: -40%;
    right: auto;
  }
  
  .tabletup_button:hover {background-color: #ee8282}
  
  .tabletup_button:active {
    background-color: #828282;
    box-shadow: 0 2px #666;
    transform: translateY(1px);
  }
  
  .mobileup_button {
    background-color: #FF5757;
    border: #47525E;
    border-radius: 100%;
    width: 81px;
    height: 81px;
    position: relative;
    z-index: 5;
    top: 13px;
    bottom: auto;
    left: -40%;
    right: auto;
  }
  
  .mobileup_button:hover {background-color: #ee8282}
  
  .mobileup_button:active {
    background-color: #828282;
    box-shadow: 0 2px #666;
    transform: translateY(1px);
  }
  
  .uparrow {
    width: 70%;
    height: auto;
    position: relative;
    z-index: 6;
    top: auto;
    
    left: auto;
    
  }
  
  .home_image {
    background-image: url(../../imgs/house_asset.png);
    width: 40px;
    height: 44px;
    position: relative;
    z-index: 6;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
  }
  
  .mission_button {
    background-color: #FFFFFF;
    border: #47525E;
    border-radius: 35px;
    width: 11%;
    height: 8%;
    position: relative;
    z-index: 5;
    top: -8.7%;
    bottom: auto;
    left: 70%;
    right: auto;
    color: #47525E;
    font-family: Lato;
    font-size: 180%;
    line-height: auto;
    text-align: center;
    font-weight: 700;
  }
  
  .mission_button:hover {background-color: #dddddd}
  
  .mission_button:active {
    background-color: #ffffff;
    box-shadow: 0 2px #666;
    transform: translateY(1px);
  }
  
  .mobilemission_button {
    background-color: #FFFFFF;
    border: #47525E;
    border-radius: 35px;
    width: 25%;
    height: 8%;
    position: relative;
    z-index: 5;
    top: -15.7%;
    bottom: auto;
    left: 35%;
    right: auto;
    color: #47525E;
    font-family: Lato;
    font-size: 180%;
    line-height: auto;
    text-align: center;
    font-weight: 700;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  
  .mobilemission_button:hover {background-color: #dddddd}
  
  .mobilemission_button:active {
    background-color: #ffffff;
    box-shadow: 0 2px #666;
    transform: translateY(1px);
  }
  
  .about_button {
    background-color: #FFFFFF;
    border: #47525E;
    border-radius: 35px;
    width: 11%;
    height: 8%;
    position: relative;
    z-index: 5;
    top: -8.7%;
    bottom: auto;
    left: 74%;
    right: auto;
    color: #47525E;
    font-family: Lato;
    font-size: 180%;
    line-height: auto;
    text-align: center;
    font-weight: 700;
  }
  
  .about_button:hover {background-color: #dddddd}
  
  .about_button:active {
    background-color: #ffffff;
    box-shadow: 0 2px #666;
    transform: translateY(1px);
  }
  
  .mobileabout_button {
    background-color: #FFFFFF;
    border: #47525E;
    border-radius: 35px;
    width: 25%;
    height: 8%;
    position: relative;
    z-index: 5;
    top: -17.2%;
    bottom: auto;
    left: 40%;
    right: auto;
    color: #47525E;
    font-family: Lato;
    font-size: 180%;
    line-height: auto;
    text-align: center;
    font-weight: 700;
    margin: 0;
    padding: 0;
   
  }
  
  .mobileabout_button:hover {background-color: #dddddd}
  
  .mobileabout_button:active {
    background-color: #ffffff;
    box-shadow: 0 2px #666;
    transform: translateY(1px);
  }
  
  .contact_button {
    background-color: #FF5757;
    border: #47525E;
    border-radius: 29px;
    width: 14%;
    height: 70px;
    position: relative;
    z-index: 5;
    top: 0%;
    bottom: auto;
    left: 65%;
    right: auto;
    color: #FFFFFF;
    font-family: Lato;
    font-size: 170%;
    line-height: auto;
    text-align: center;
    font-weight: 700;
    text-shadow: 1px 2px 3px rgba(0,0,0,0.39);
  }
  
  .contact_button:hover {background-color: #ee8282}
  
  .contact_button:active {
    background-color: #828282;
    box-shadow: 0 2px #666;
    transform: translateY(1px);
  }
  
  .tabletcontact_button {
    background-color: #FF5757;
    border: #47525E;
    border-radius: 29px;
    width: 25%;
    height: 70px;
    position: relative;
    z-index: 5;
    top: 1px;
    bottom: auto;
    left: 38%;
    right: auto;
    color: #FFFFFF;
    font-family: Lato;
    font-size: 170%;
    line-height: auto;
    text-align: center;
    font-weight: 700;
    text-shadow: 1px 2px 3px rgba(0,0,0,0.39);
  }
  
  .tabletcontact_button:hover {background-color: #ee8282}
  
  .tabletcontact_button:active {
    background-color: #828282;
    box-shadow: 0 2px #666;
    transform: translateY(1px);
  }
  
  .mobilecontact_button {
    background-color: #FF5757;
    border: #47525E;
    border-radius: 29px;
    width: 25%;
    height: 70px;
    position: relative;
    z-index: 5;
    top: -13px;
    bottom: auto;
    left: 38%;
    right: auto;
    color: #FFFFFF;
    font-family: Lato;
    font-size: large;
    line-height: auto;
    text-align: center;
    font-weight: 700;
    text-shadow: 1px 2px 3px rgba(0,0,0,0.39);
    margin: 0.1%, 0.1%, 0.1%, 0.1%;
  }
  
  .mobilecontact_button:hover {background-color: #ee8282}
  
  .mobilecontact_button:active {
    background-color: #828282;
    box-shadow: 0 2px #666;
    transform: translateY(1px);
  }
  
  .support_button {
    background-color: #FF5757;
    border: #47525E;
    border-radius: 29px;
    width: 13%;
    height: 70px;
    position: relative;
    z-index: 5;
    top: auto;
    bottom: auto;
    left: 69%;
    right: auto;
    color: #FFFFFF;
    font-family: Lato;
    font-size: 170%;
    line-height: auto;
    text-align: center;
    font-weight: 700;
    text-shadow: 1px 2px 3px rgba(0,0,0,0.39);
    margin: 0;
    padding: 0;
  }
  
  .support_button:hover {background-color: #ee8282}
  
  .support_button:active {
    background-color: #828282;
    box-shadow: 0 2px #666;
    transform: translateY(1px);
  }
  
  .tabletsupport_button {
    background-color: #FF5757;
    border: #47525E;
    border-radius: 29px;
    width: 24%;
    height: 70px;
    position: relative;
    z-index: 5;
    top: 1px;
    bottom: auto;
    left: 45%;
    right: auto;
    color: #FFFFFF;
    font-family: Lato;
    font-size: 170%;
    line-height: auto;
    text-align: center;
    font-weight: 700;
    text-shadow: 1px 2px 3px rgba(0,0,0,0.39);
    margin: 0;
    padding: 0;
  }
  
  .tabletsupport_button:hover {background-color: #ee8282}
  
  .tabletsupport_button:active {
    background-color: #828282;
    box-shadow: 0 2px #666;
    transform: translateY(1px);
  }
  
  .mobilesupport_button {
    background-color: #FF5757;
    border: #47525E;
    border-radius: 29px;
    width: 24%;
    height: 70px;
    position: relative;
    z-index: 5;
    top: 1px;
    bottom: auto;
    left: 45%;
    right: auto;
    color: #FFFFFF;
    font-family: Lato;
    font-size: 170%;
    line-height: auto;
    text-align: center;
    font-weight: 700;
    text-shadow: 1px 2px 3px rgba(0,0,0,0.39);
    margin: 0;
    padding: 0;
  }
  
  .mobilesupport_button:hover {background-color: #ee8282}
  
  .mobilesupport_button:active {
    background-color: #828282;
    box-shadow: 0 2px #666;
    transform: translateY(1px);
  }
  
  
  
  .page_layout {
    background-image: url(../../imgs/BackgroundJapanesefield.png);
    background-size: cover;
    z-index: 1;
    opacity: 1;
    width: auto;
    height: 1080px;
    position: relative;
    margin: 0;
    padding: 0;
    overflow-x: visible;
  }
  
  .parent {
    margin: 1rem;
    padding: 2rem 2rem;
    text-align: center;
    
  }
  .child {
    display: inline-block;
    margin: 0;
    padding: 0;
    vertical-align: middle;
  }
  
  
  .title_mobile{
    color: #ffffff;
    font-family: Abril Fatface;
    font-size: 60px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 72px;
    width: 400px;
    text-align: center;
    position: relative;
    z-index: 5;
    top: 43%;
    bottom: auto;
    left: 10%;
    right: auto;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  
  .title_tablet{
    color: #ffffff;
    font-family: Abril Fatface;
    font-size: 60px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 72px;
    width: 400px;
    text-align: center;
    position: relative;
    z-index: 5;
    top: 43%;
    bottom: auto;
    left: 25vw;
    right: auto;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  
  .title_browser{
    color: #ffffff;
    font-family: Abril Fatface;
    font-size: 60px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 72px;
    max-width: 564px;
    text-align: center;
    position: relative;
    z-index: 5;
    top: 36%;
    bottom: auto;
    left: 15lvw;
    right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 57vw;
    
    margin-right: 10%;
  }
  
  
  .tab1{
    tab-size: 1;
  }
  
  .tab2{
    tab-size: 2;
  }
  
  .tab3{
    tab-size: 3;
  }
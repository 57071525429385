.loginform{
    margin: 2;
    padding: 1;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    position: relative;
    background-color: #a2a2a2;
    justify-content: center;
    flex-direction: column;
    width: 80lvw;
    height: 500px;  
    max-width: 600px;
    display: inline-block;
    border-radius: 50px;
}

.box{
    background-image: url("../../imgs/Lead-Gray-600x600.jpg");
    width: 30lvw;
    height: 500px;
    min-width: 285px;
    display: inline-block;
    max-width: 600px;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: white;
    font-size: x-large;
    position: relative;
    
}
.createbutton{
    color: black;
    font-size: large;
    position: relative;
    top: 150px;
    border-radius: 50px;
}

.signintext{
    font-size: xx-large;
    margin: 5px;
    font-weight: 600;
    position: relative;
    top: 100px;
}

.loginSubmit{
    color: white;
    font-weight: 500;
    background-color: green;
    border-radius: 30px;
    font-size: x-large;
    width: 40%;
    height: 10%;
    
}

.loginquestionTitles{
    color: black;
    font-size: larger;
    font-weight: 580;
  }

  .login {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #dcdcdc;
    padding: 30px;
    
    height: 500px;
  }

  .login__textBox {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .login__btn {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    border: none;
    color: white;
    background-color: black;
  }

  .login__google {
    background-color: #4285f4;
  }
  
  .login div {
    margin-top: 7px;
  }
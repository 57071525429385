.navbar {
    padding: 10px;
    display: flex;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    border-bottom: 1px solid #f2f2f2;
    overflow: visible;
  }
  .navbar h1 {
    color: #FF5757;
    font-size: larger;
  }
  .navbar h1:hover {
    color: #999999;
  }
  .navbar h1:active {
    box-shadow: 0 2px #d8d8d8;
    transform: translateY(1.5px); 
  }
  .navbar .links {
    margin-left: auto;
  }
  .navbar a {
    margin-left: 40px;
    margin-right: auto;
    text-decoration: none;
    padding: 6px;
    font-size:x-large;
  }
  .navbar a:hover {
    color: #f1356d;
  }

  .navbar a:active {
    box-shadow: 0 2px #d8d8d8;
    transform: translateY(1.5px); 
  }

  .content {
    max-width: 100%;
  }
  
  


body {
    text-align: center;
    
}
.parent {
    margin: 1rem;
    padding: 2rem 2rem;
    text-align: center; 
  }
.child {
    display: inline-block;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    margin-right: 1rem;
  }

.form{
    margin: 2;
    padding: 1;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    position: relative;
    background-color: #a2a2a2;
    justify-content: center;
    flex-direction: column;
    width: 80lvw;
    height: 500px;  
    max-width: 600px;
    display: inline-block;
    border-radius: 50px;
}
.textbox{
    width: 70lvw;
    max-width: 400px;
    resize: none;
}

.tabletform{
    margin: 2;
    padding: 1;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    position: absolute;
    background-color: #a2a2a2;
    justify-content: center;
    flex-direction: column;
    top: 13%;
    left: auto;
    width: 481px;
    height: 500px;  
}

.mobileform{
    margin: 2;
    padding: 1;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    position: absolute;
    background-color: #a2a2a2;
    justify-content: center;
    flex-direction: column;
    top: 15%;
    left: auto;
    width: 338.204px;
    height: 500px;  
}

.mission_logo {
    border: 0px solid #A6A6A6;
    box-shadow: 0px 0px #a7a6a6;
    display: inline-block;
    position: relative;
    width: 80lvw;
    max-width: 500px;
  }

  .questionTitles{
    color: black;
    font-size: larger;
    font-weight: 580;
  }
  .submit{
    color: white;
    font-weight: 500;
    background-color: green;
    border-radius: 30px;
    font-size: x-large;
    width: 80%;
    height: 10%;
    
  }